<template>
  <div class="container" ref="wrapper">
    <van-pull-refresh v-model="refreshisLoading" @refresh="onRefresh">
      <van-loading
        class="loading"
        color="#9d1d22"
        text-color="#9d1d22"
        v-if="loading"
        size="24px"
        >加载中...</van-loading
      >
      <div v-if="loading == false">
        <!-- <van-sticky></van-sticky> -->
        <div></div>
        <van-nav-bar @click-left="toUserInfo()">
          <template #left>
            <van-image
              style="float: left"
              round
              width="41px"
              height="41px"
              :src="userInfo.headImgurl"
            />

            <h3 style="margin-left: 5px">{{ userInfo.joinTime }}</h3>
            <userIcon :iconstring="userInfo.icon"></userIcon>
            <!-- <van-icon name="search" size="18" @click="onSearch()" /> -->
          </template>
        </van-nav-bar>
        <!-- <div v-if="workDay.length>0">{{workDay}}</div> -->

        <van-row>
          <van-col span="24" style="margin-top: 5px">
            <van-search
              v-model="keyword"
              @search="onSearch"
              shape="round"
              placeholder="请输入搜索关键词"
            />
          </van-col>
        </van-row>

        <div v-for="(seting, setingindex) in setingBase" :key="setingindex">
          <!-- 轮播图 -->
          <div v-if="seting.isShow && seting.setType == 100">
            <van-swipe class="my-swipe" :autoplay="3000">
              <van-swipe-item
                v-for="(image, index) in swipeImages"
                :key="index"
              >
                <img
                  v-lazy="image.imageUrl"
                  :data_link="image.link"
                  @click="pimageClick(image.link)"
                />
              </van-swipe-item>
            </van-swipe>

            <van-notice-bar
              v-if="workDay != undefined && workDay != ''"
              style="margin-top: 10px"
              left-icon="volume-o"
              :text="workDay"
            />
            <!--励志图文-->
            <div v-if="workDayImage != undefined && workDayImage != ''">
              <div
                style="text-align: center"
                class="workdayImageClick"
                @click="workDayImageShowClick"
              >
                <img
                  style="max-height: 24px"
                  :src="
                    workDayImageShow
                      ? '/image/up_icon.png'
                      : '/image/down_icon.png'
                  "
                />
              </div>

              <div
                v-show="workDayImageShow"
                class="workdayImageItme"
                style="text-align: center"
              >
                <!-- <van-overlay :show="show" @click="showWorkdayImg">
                <div class="wrapper" @click.stop> -->
                <img
                  style="max-width: 90%"
                  :src="workDayImage"
                  @click="showWorkdayImg"
                />
                <!-- <div class="block"></div> -->
                <!-- </div>
              </van-overlay> -->
              </div>
            </div>
            <!-- 遮罩层 -->
            <van-overlay :show="show">
              <div class="wrapper" @click.stop>
                <img
                  :src="workDayImage"
                  style="max-width: 90%"
                  @click="showWorkdayImg"
                  @touchstart="gtouchstart()"
                  @touchend="showDeleteButton()"
                />
              </div>
            </van-overlay>
            <!-- 分享 -->
            <van-share-sheet
              v-model="showShare"
              title="立即分享给好友"
              :options="options"
              @select="onSelect"
            />
          </div>
          <!--导航,折叠合并-->
          <div v-if="seting.isShow && seting.setType == 101" class="NavList">
            <van-grid
              v-if="NavItmesMain != null && NavItmesMain.length > 0"
              :gutter="0"
              :column-num="5"
              :route="true"
              :border="false"
            >
              <van-grid-item
                v-for="(nav, index) in NavItmesMain"
                :key="index"
                v-bind:icon="nav.src"
                v-bind:text="nav.title"
                @click="navClick(nav)"
              >
                <template v-if="nav.isMain && nav.title == '中酿宣传'">
                  <van-popover
                    v-model="showPopover"
                    trigger="click"
                    placement="right"
                  >
                    <van-grid
                      square
                      clickable
                      :border="false"
                      column-num="2"
                      style="width: 180px"
                    >
                      <van-grid-item
                        v-for="(nav, index) in ArticleNavItmes"
                        :key="index"
                        v-bind:icon="nav.src"
                        v-bind:text="nav.title"
                        @click="navClick(nav)"
                      />
                    </van-grid>
                    <template #reference>
                      <div
                        class="
                          van-grid-item__content van-grid-item__content--center
                        "
                      >
                        <i class="van-icon van-grid-item__icon">
                          <img :src="nav.src" class="van-icon__image"
                        /></i>
                        <span class="van-grid-item__text">{{ nav.title }}</span>
                      </div>
                    </template>
                  </van-popover>
                </template>

                <template v-if="nav.isMain && nav.title == '我的工作台'">
                  <van-popover
                    v-model="showPopover2"
                    trigger="click"
                    placement="right"
                  >
                    <van-grid
                      square
                      clickable
                      :border="false"
                      column-num="3"
                      style="width: 270px"
                    >
                      <van-grid-item
                        v-for="(nav, index) in WorkNavItmes"
                        :key="index"
                        v-bind:icon="nav.src"
                        v-bind:text="nav.title"
                        @click="navClick(nav)"
                      />
                    </van-grid>
                    <template #reference>
                      <div
                        class="
                          van-grid-item__content van-grid-item__content--center
                        "
                      >
                        <i class="van-icon van-grid-item__icon">
                          <img :src="nav.src" class="van-icon__image"
                        /></i>
                        <span class="van-grid-item__text">{{ nav.title }}</span>
                      </div>
                    </template>
                  </van-popover>
                </template>

                <template v-if="nav.isMain && nav.title == '产品项目'">
                  <van-popover
                    v-model="showPopover3"
                    trigger="click"
                    placement="right"
                  >
                    <van-grid
                      square
                      clickable
                      :border="false"
                      column-num="2"
                      style="width: 180px"
                    >
                      <van-grid-item
                        v-for="(nav, index) in ProjectNavItmes"
                        :key="index"
                        v-bind:icon="nav.src"
                        v-bind:text="nav.title"
                        @click="navClick(nav)"
                      />
                    </van-grid>
                    <template #reference>
                      <div
                        class="
                          van-grid-item__content van-grid-item__content--center
                        "
                      >
                        <i class="van-icon van-grid-item__icon">
                          <img :src="nav.src" class="van-icon__image"
                        /></i>
                        <span class="van-grid-item__text">{{ nav.title }}</span>
                      </div>
                    </template>
                  </van-popover>
                </template>
              </van-grid-item>
            </van-grid>
          </div>

          <div v-if="seting.isShow && seting.setType == 102">
            <van-notice-bar
              v-if="Notice != ''"
              style="margin-top: 10px"
              left-icon="volume-o"
              :text="Notice"
              @click="navNotice(NoticeItem)"
            />
          </div>
          <div v-if="seting.isShow && seting.setType == 103">
            <div v-if="hottexts.length > 0 || hotimages.length > 0">
              <h2 style="padding: 2px 1px 5px 18px">热门推荐</h2>

              <!-- <van-row>
            <van-col
              span="12"
              v-for="(h, index) in hotimages"
              :key="index"
              @click="hotClick(h)"
            >
              
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="h.imageUrl"
              />

              <div style="height: 28px; padding: 8px 10px">
                <span
                  style="display: inline-block; height: 21px"
                  class="van-multi-ellipsis--l3"
                >
                  {{ h.title }}
                </span>
              </div>
              <div style="height: 25px; padding: 0px 10px">
                <div style="float: left; color: #a8a8a8">
                  {{ h.readCount }}人阅读
                </div>
                <div style="float: right">
                  <van-tag round color="#9d1d22" size="medium"
                    >点击前往</van-tag
                  >
                </div>
              </div>
            </van-col>
          </van-row> -->

              <van-swipe :autoplay="3500">
                <van-swipe-item
                  v-for="(h2, index) in hotimagespuls"
                  :key="index"
                >
                  <van-row>
                    <van-col
                      span="12"
                      v-for="(h, index) in h2"
                      :key="index"
                      @click="hotClick(h)"
                    >
                      <van-image
                        lazy-load
                        class="w100"
                        fit="contain"
                        style="max-height: 200px"
                        :src="h.imageUrl"
                      />

                      <div style="height: 28px; padding: 8px 10px">
                        <span
                          style="display: inline-block; height: 21px"
                          class="van-multi-ellipsis--l3"
                        >
                          {{ h.title }}
                        </span>
                      </div>
                      <div style="height: 25px; padding: 0px 10px">
                        <div style="float: left; color: #a8a8a8">
                          {{ h.readCount }}人阅读
                        </div>
                        <div style="float: right">
                          <van-tag round color="#9d1d22" size="medium"
                            >点击前往</van-tag
                          >
                        </div>
                      </div>
                    </van-col>
                  </van-row>
                </van-swipe-item>
              </van-swipe>

              <div class="mydivider" v-if="hotimages.length > 0"></div>

              <van-list>
                <van-cell
                  v-for="(h, index) in hottexts"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <div class="tops" style="float: left">
                    <div>Top{{ index + 1 }}</div>
                  </div>
                  <div style="float: left; margin-left: 4px">
                    {{ h.title }}
                  </div>
                  <div style="clear: left; color: #a8a8a8">
                    {{ h.readCount }}人阅读
                  </div>
                </van-cell>
              </van-list>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 104"></div>
          <div v-if="seting.isShow && seting.setType == 5">
            <div v-if="newBBs.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">中酿心声</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/bbs')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>

              <van-list>
                <van-cell
                  v-for="(h, index) in newBBs"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-col span="3">
                    <van-image
                      style="float: left"
                      round
                      width="41px"
                      height="41px"
                      :src="h.headImgurl"
                    />
                  </van-col>

                  <van-col span="11">
                    <div
                      style="
                        float: left;
                        color: #069;
                        font-size: 16px;
                        font-weight: bold;
                        width: 100%;
                      "
                    >
                      {{ h.trueName }}
                      <userIcon :iconstring="h.icon"></userIcon>
                    </div>

                    <div style="float: left; color: #696969">
                      {{ h.creationTime | relTime }}
                    </div>
                    <div style="float: left; color: #696969; margin-left: 13px">
                      浏览{{ h.readCount }}人次
                    </div>
                  </van-col>
                  <van-col span="10"> </van-col>

                  <van-col span="2"></van-col>

                  <van-col span="22">
                    <div style="float: left; margin-left: 4px">
                      {{ h.title }}
                    </div>
                  </van-col>
                  <!-- <div class="tops" style="float: left">
              <div>Top{{ index + 1 }}</div>
            </div> -->
                </van-cell>
              </van-list>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 10200">
            <div v-if="newAtricle102.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">进阶S7攻略</h2>
                </van-col>
                <van-col
                  span="6"
                  @click="pimageClick('/s7strategy/list?type=b')"
                >
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>
              <van-list>
                <van-cell
                  v-for="(h, index) in newAtricle102"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-col span="6"
                    ><van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="max-height: 200px"
                      :src="h.imageUrl"
                    />
                  </van-col>
                  <van-col span="18" align="bottom">
                    <div
                      style="display: table-cell; vertical-align: middle"
                      class="zntitle van-multi-ellipsis--l3"
                    >
                      <h3 style="display: inline-block">{{ h.title }}</h3>
                    </div>
                    <div style="float: right">{{ h.creationTime }}</div>
                    <div style="float: right; margin-right: 13px">
                      浏览{{ h.readCount }}人次
                    </div>
                  </van-col>
                </van-cell>
              </van-list>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 10600">
            <div v-if="newAtricle106.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">市场宣传</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/article?type=106')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>

              <van-row>
                <van-col
                  span="12"
                  v-for="(h, index) in newAtricle106"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    :src="h.imageUrl"
                  >
                  </van-image>

                  <div style="height: 28px; padding: 8px 10px">
                    <span
                      style="display: inline-block; height: 21px"
                      class="van-multi-ellipsis--l3"
                    >
                      {{ h.title }}
                    </span>
                  </div>
                  <div style="height: 25px; padding: 0px 10px">
                    <div style="float: left; color: #a8a8a8">
                      {{ h.readCount }}人阅读
                    </div>
                    <div style="float: right">
                      <van-tag round color="#9d1d22" size="medium"
                        >点击前往</van-tag
                      >
                    </div>
                  </div>
                  <div class="mydivider"></div>
                </van-col>
              </van-row>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 10000">
            <div v-if="newAtricle100.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">中酿资讯</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/article?type=100')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>
              <van-list>
                <van-cell
                  v-for="(h, index) in newAtricle100"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-col span="6"
                    ><van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="max-height: 200px"
                      :src="h.imageUrl"
                    >
                    </van-image>
                  </van-col>
                  <van-col span="18" align="bottom">
                    <div
                      style="display: table-cell; vertical-align: middle"
                      class="zntitle van-multi-ellipsis--l3"
                    >
                      <h3 style="display: inline-block">{{ h.title }}</h3>
                    </div>
                    <div style="float: right">{{ h.creationTime }}</div>
                    <div style="float: right; margin-right: 13px">
                      浏览{{ h.readCount }}人次
                    </div>
                  </van-col>
                </van-cell>
              </van-list>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 10100">
            <div v-if="newAtricle101.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">中酿公告</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/article?type=101')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>

              <van-list>
                <van-cell
                  v-for="(h, index) in newAtricle101"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-col span="6"
                    ><van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="max-height: 200px"
                      :src="h.imageUrl"
                    >
                    </van-image>
                  </van-col>
                  <van-col span="18" align="bottom">
                    <div
                      style="display: table-cell; vertical-align: middle"
                      class="zntitle van-multi-ellipsis--l3"
                    >
                      <h3 style="display: inline-block">{{ h.title }}</h3>
                    </div>
                    <div style="float: right">{{ h.creationTime }}</div>
                    <div style="float: right; margin-right: 13px">
                      浏览{{ h.readCount }}人次
                    </div>
                  </van-col>
                </van-cell>
              </van-list>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 3">
            <div v-if="newProduct.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">中酿产品</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/product/list')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>

              <van-row>
                <van-col
                  span="12"
                  v-for="(h, index) in newProduct"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-image
                    lazy-load
                    class="w100"
                    fit="contain"
                    style="max-height: 200px"
                    :src="h.imageUrl"
                  >
                  </van-image>

                  <div style="height: 28px; padding: 8px 10px">
                    <span
                      style="display: inline-block; height: 21px"
                      class="van-multi-ellipsis--l3"
                    >
                      {{ h.title }}
                    </span>
                  </div>
                  <div style="height: 25px; padding: 0px 10px">
                    <div style="float: left; color: #a8a8a8">
                      {{ h.readCount }}人阅读
                    </div>
                    <div style="float: right">
                      <van-tag round color="#9d1d22" size="medium"
                        >点击前往</van-tag
                      >
                    </div>
                  </div>
                  <div class="mydivider"></div>
                </van-col>
              </van-row>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 4">
            <div v-if="newCourse.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">中酿学院 线上课程</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/course/list?type=a')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>

              <van-list>
                <van-cell
                  v-for="(h, index) in newCourse"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-col span="6"
                    ><van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="max-height: 200px"
                      :src="h.imageUrl"
                    >
                    </van-image>
                  </van-col>
                  <van-col span="18" align="bottom">
                    <div
                      style="display: table-cell; vertical-align: middle"
                      class="zntitle van-multi-ellipsis--l3"
                    >
                      <h3 style="display: inline-block">{{ h.title }}</h3>
                    </div>
                    <!-- <div style="float: right">{{ h.creationTime }}</div> -->
                    <div style="float: right; margin-right: 13px">
                      浏览{{ h.readCount }}人次
                    </div>
                  </van-col>
                </van-cell>
              </van-list>
            </div>
          </div>
          <div v-if="seting.isShow && seting.setType == 41">
            <div v-if="newCourseOffline.length > 0">
              <van-row>
                <van-col span="18">
                  <h2 style="padding: 2px 1px 5px 18px">中酿学院 线下课程</h2>
                </van-col>
                <van-col span="6" @click="pimageClick('/course/list?type=b')">
                  <div style="float: right; padding: 8px 20px 0px 0px">
                    更多<van-icon name="arrow" /></div
                ></van-col>
              </van-row>

              <van-list>
                <van-cell
                  v-for="(h, index) in newCourseOffline"
                  :key="index"
                  @click="hotClick(h)"
                >
                  <van-col span="6"
                    ><van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="max-height: 200px"
                      :src="h.imageUrl"
                    >
                    </van-image>
                  </van-col>
                  <van-col span="18" align="bottom">
                    <div
                      style="display: table-cell; vertical-align: middle"
                      class="zntitle van-multi-ellipsis--l3"
                    >
                      <h3 style="display: inline-block">{{ h.title }}</h3>
                    </div>
                    <!-- <div style="float: right">{{ h.creationTime }}</div> -->
                    <div style="float: right; margin-right: 13px">
                      浏览{{ h.readCount }}人次
                    </div>
                  </van-col>
                </van-cell>
              </van-list>
            </div>
          </div>
        </div>

        <!-- <div class="mydivider"></div> -->

        <!-- <div v-if="newCloudDisk.length > 0">
        <h2 style="padding: 2px 1px 5px 18px">资料</h2>
        <van-list>
          <van-cell
            v-for="(h, index) in newCloudDisk"
            :key="index"
            @click="hotClick(h)"
          >
            <div class="tops" style="float: left">
              <div>Top{{ index + 1 }}</div>
            </div>
            <div style="float: left; margin-left: 4px">
              {{ h.title }}
            </div>
            <div style="clear: left; color: #a8a8a8">
              {{ h.readCount }}人阅读
            </div>
          </van-cell>
        </van-list>
      </div> -->
      </div>
    </van-pull-refresh>

    <div
      class="new-chance-dialog"
      v-show="showdialog"
      v-if="systemRemind.detailsHtml != undefined"
    >
      <div :class="systemRemind.typeId==6?'wraper userCertificate': 'wraper'">
        <div class="title-wrap">
          <div class="J-close-btn" @click="closedialog()"></div>
          <!-- <div class="main-title">
            <p><span>免费电话咨询</span></p>
          </div>
          <div class="sub-title"><p>快速响应，有效解决任何商标疑问</p></div> -->
        </div>
        <div class="chance-form" v-html="systemRemind.detailsHtml"></div>
        <div>
        <van-button
          round
          type="info"
          v-if="systemRemind.typeId == 3"
          style="margin-bottom: 20px"
          :disabled="disabledReceived"
          @click="receivedSystemMessageHandle()"
          >领取生日积分200</van-button
        >
        <van-button
          round
          type="info"
          v-if="systemRemind.typeId == 4"
          style="margin-bottom: 20px"
          :disabled="disabledReceived"
          @click="receivedSystemMessageHandle()"
          >领取入职周年积分200</van-button
        >
        <van-button
          round
          type="info"
          v-if="systemRemind.typeId == 5 ||systemRemind.typeId == 6"
          style="margin-bottom: 20px"
          :disabled="disabledReceived"
          @click="receivedSystemMessageHandle()"
          >收到</van-button
        >
      </div>
        <div class="chance-load ipr-hide"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
//import store from '@/store'
import {
  getSetingBase,
  getSetingList,
  updateReadCount,
  GetWorkDay,
  GetShareEndeavorImage,
} from "@/api/seting";
import { GetUserWelcome, checkUser } from "@/api/user";
import { GetSystemScore } from "@/api/score";
import {
  getSystemRemindByUser,
  receivedSystemMessage,
} from "@/api/systemmessage";
import { GetWXMiniAppJsConfig } from "@/api/wxuser";
import store from "@/store";
import { mapMutations } from "vuex";
import UserIcon from "../components/userIcon";
import { createUser as createGrafanaUser } from "@/api/keshihua";

export default {
  name: "home",
  data() {
    return {
      setingBase: [],
      scroll: 0,
      refreshisLoading: false,
      userInfo: {},
      loading: true,
      Notice: "",
      NoticeItem: {},
      keyword: "",
      swipeImages: [],
      NavItmes: [],
      hotimagespuls: [],
      hotimages: [],
      hottexts: [],
      newAtricle100: [],
      newAtricle101: [],
      newAtricle102: [],
      newAtricle106: [],
      newProduct: [],
      newCourse: [],
      newCourseOffline: [],
      newBBs: [],
      newCloudDisk: [],
      workDay: "",
      workDayImage: "",
      workDayImageShow: true,
      hassystemRemind: false, //是否有系统提醒
      systemRemind: {}, //系统提醒类型
      disabledReceived: false,
      showdialog: false,
      showPopover: false,
      showPopover2: false,
      showPopover3: false,
      isWeixin:
        navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1,
      isMiniprogram: false,
      show: false,
      showShare: false,
      options: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link" },
        { name: "分享海报", icon: "poster" },
        { name: "二维码", icon: "qrcode" },
      ],
    };
  },
  components: {
    UserIcon,
  },
  computed: {
    NavItmes1: function () {
      var navarray = [];
      for (let index = 0; index < this.NavItmes.length && index < 10; index++) {
        navarray.push(this.NavItmes[index]);
      }
      return navarray;
    },
    NavItmes2: function () {
      var navarray = [];
      if (this.NavItmes.length > 10) {
        for (
          let index = 10;
          index < this.NavItmes.length && index < 20;
          index++
        ) {
          navarray.push(this.NavItmes[index]);
        }
      }
      return navarray;
    },
    NavItmes3: function () {
      var navarray = [];
      if (this.NavItmes.length > 20) {
        for (
          let index = 20;
          index < this.NavItmes.length && index < 30;
          index++
        ) {
          navarray.push(this.NavItmes[index]);
        }
      }
      return navarray;
    },
    NavItmes4: function () {
      var navarray = [];
      if (this.NavItmes.length > 30) {
        for (
          let index = 30;
          index < this.NavItmes.length && index < 40;
          index++
        ) {
          navarray.push(this.NavItmes[index]);
        }
      }
      return navarray;
    },
    //主要模块
    NavItmesMain: function () {
      var navarray = [];
      for (let index = 0; index < this.NavItmes.length; index++) {
        if (
          this.NavItmes[index].title == "市场宣传" ||
           this.NavItmes[index].title == "品牌营销" ||
               this.NavItmes[index].title == "名酒体验中心" ||
               this.NavItmes[index].title == "素材库" ||
          this.NavItmes[index].title == "中酿内刊" ||
          this.NavItmes[index].title == "中酿公告" ||
          this.NavItmes[index].title == "荣誉奋斗者" ||
          this.NavItmes[index].title == "中酿企宣" ||
          this.NavItmes[index].title == "中酿资讯" ||
          //this.NavItmes[index].title == "名酒会预约" ||
          this.NavItmes[index].title == "生产到货" ||
          this.NavItmes[index].title == "积分商城" ||
          this.NavItmes[index].title == "品牌CRM" ||
          this.NavItmes[index].title == "项目周进度" ||
          this.NavItmes[index].title == "中酿资料库" ||
          this.NavItmes[index].title == "扫码营销" ||
          this.NavItmes[index].title == "产品价格" ||
          this.NavItmes[index].title == "百年糊涂" ||
          //this.NavItmes[index].title == "工作总结" ||
          //this.NavItmes[index].title == "意见反馈" ||
          //this.NavItmes[index].title == "定制" ||
          this.NavItmes[index].title == "我的工作台"
        ) {
          continue;
        } else {
          navarray.push(this.NavItmes[index]);
          if (this.NavItmes[index].title == "进阶S7攻略") {
            navarray.push({
              src: "http://kmfile.bjznpz.com/wwwroot/zn_files/kmimage/ArticleNav.jpg",
              isMain: true,
              title: "中酿宣传",
            });
          }
          if (this.NavItmes[index].title == "中酿心声") {
            navarray.push({
              src: "http://kmfile.bjznpz.com/wwwroot/zn_files/kmimage/workNav.jpg",
              isMain: true,
              title: "我的工作台",
            });

            navarray.push({
              src: "http://kmfile.bjznpz.com/wwwroot/zn_files/kmimage/ProjectNav.png",
              isMain: true,
              title: "产品项目",
            });
          }

          //     if (this.NavItmes[index].title == "中酿公告") {

          // }
        }
      }
      return navarray;
    },
    //宣传模块  http://kmfile.bjznpz.com/wwwroot/zn_files/210326/de47ae231cf144259a537ce8633a0a99.png
    ArticleNavItmes: function () {
      var navarray = [];
      for (let index = 0; index < this.NavItmes.length; index++) {
        if (
          this.NavItmes[index].title == "市场宣传" ||
            this.NavItmes[index].title == "品牌营销" ||
               this.NavItmes[index].title == "名酒体验中心" ||
               this.NavItmes[index].title == "素材库" ||
          this.NavItmes[index].title == "中酿内刊" ||
          //this.NavItmes[index].title == "中酿公告" ||
          this.NavItmes[index].title == "荣誉奋斗者" ||
          this.NavItmes[index].title == "中酿企宣" ||
          this.NavItmes[index].title == "中酿资讯"
        ) {
          navarray.push(this.NavItmes[index]);
        }
      }
      return navarray;
    },

    //宣传模块  http://kmfile.bjznpz.com/wwwroot/zn_files/210326/de47ae231cf144259a537ce8633a0a99.png
    ProjectNavItmes: function () {
      var navarray = [];
      for (let index = 0; index < this.NavItmes.length; index++) {
        if (
          this.NavItmes[index].title == "项目周进度" ||
          this.NavItmes[index].title == "生产到货" ||
          this.NavItmes[index].title == "产品价格" ||
          this.NavItmes[index].title == "百年糊涂"
        ) {
          navarray.push(this.NavItmes[index]);
        }
      }
      return navarray;
    },

    //工作模块  http://kmfile.bjznpz.com/wwwroot/zn_files/210519/07a880a96c694672988f30490e83b4db.png
    WorkNavItmes: function () {
      var navarray = [];
      for (let index = 0; index < this.NavItmes.length; index++) {
        if (
          //this.NavItmes[index].title == "名酒会预约" ||
          //this.NavItmes[index].title == "生产到货" ||
          this.NavItmes[index].title == "积分商城" ||
          this.NavItmes[index].title == "品牌CRM" ||
          //this.NavItmes[index].title == "项目周进度" ||
          this.NavItmes[index].title == "中酿资料库" ||
          this.NavItmes[index].title == "扫码营销" ||
          //this.NavItmes[index].title == "产品价格" ||
          //this.NavItmes[index].title == "意见反馈" ||
          this.NavItmes[index].title == "中酿公告" ||
          //this.NavItmes[index].title == "工作总结" ||
          //this.NavItmes[index].title == "定制" ||
          this.NavItmes[index].title == "我的工作台"
        )
          navarray.push(this.NavItmes[index]);
      }
      return navarray;
    },
  },
  created() {
    console.log("created");
    console.log(111, this.NavItmes);
    // if(store.state.user.trueName!=undefined){
    //     //this.trueName=store.state.user.trueName

    // }
    //this.initData();
  },
  // watch: {
  //   $route(to, from) {
  //     //监听路由是否变化
  //     if (to.query != from.query) {
  //       this.initData();
  //     }
  //   },
  // },
  activated() {
    if (this.NavItmes.length == 0) {
      this.initData();
    }
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  beforeCreate() {},
  methods: {
    ...mapMutations(["clearUser"]),
    // 遮罩层
    showWorkdayImg() {
      this.show = !this.show;
    },
    onSelect(option) {
      Toast(option.name);
      this.showShare = false;
    },
    //长按事件（起始）
    gtouchstart() {
      this.timeOutEvent = setTimeout(function () {
        console.log("开始长安");
      }, 2000); //这里设置定时器，定义长按500毫秒触发长按事件
      return false;
    },
    //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
    showDeleteButton() {
      clearTimeout(this.timeOutEvent); //清除定时器
      if (this.timeOutEvent != 0) {
        //这里写要执行的内容（如onclick事件）
      }
      return false;
    },

    //真正长按后应该执行的内容
    longPress() {
      this.timeOutEvent = 0;
      //执行长按要执行的内容，如弹出菜单
      this.showShare = true;
    },
    // imgbigClick() {
    //   // console.log(e);
    //   // console.log(this.$refs.workdayimgRef);
    //   console.log(this.$refs.workdayimgRef.style);
    //   this.$refs.workdayimgRef.style = "width:100%;position:fixed;top:0;";
    //   // this.$refs.workdayimgRef.style = "height:100%";
    // },

    onRefresh() {
      (this.loading = true), this.initData();
      this.refreshisLoading = false;
      Toast("刷新成功");
      // console.log(NavItmesMain);
    },
    async initData() {
      this.setingBase = [];
      this.swipeImages = [];
      this.NavItmes = [];
      this.hotimages = [];
      this.hotimagespuls = [];
      this.hottexts = [];
      this.newAtricle100 = []; //10100
      this.newAtricle101 = []; // 10101
      this.newAtricle102 = []; // 10102
      this.newAtricle106 = [];
      this.newProduct = []; //2
      this.newCourse = []; //3
      this.newCourseOffline = []; //3
      this.newBBs = []; //4
      this.newCloudDisk = []; //5
      this.disabledReceived = false;

      if (this.isWeixin) {
        try {
          var jsConfig = await GetWXMiniAppJsConfig(
            encodeURIComponent(location.href.split("#")[0])
          );

          let win = window;
          if (win.wx) {
            win.wx.config({
              debug: false,
              appId: jsConfig.appId,
              timestamp: jsConfig.timestamp,
              nonceStr: jsConfig.nonceStr,
              signature: jsConfig.signature,
              jsApiList: ["checkJsApi"],
            });
            win.wx.ready(() => {
              console.log("微信jssdk加载成功");
            });
            win.wx.error((res) => {
              console.log("微信jssdk加载失败" + res.errMsg);
            });

            win.wx.miniProgram.getEnv((res) => {
              console.log(res);
              if (res.miniprogram) {
                console.log("在小程序里");
              } else {
                console.log("不在小程序");
              }
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("不在微信中");
      }

      if (!this.$route.query.status) {
        var checkUserResult = await checkUser();
        if (checkUserResult.data.data != 0) {
          if (checkUserResult.data.data > 400) {
            this.lgout();
          }

          if (checkUserResult.data.data == 1) {
            this.$router.replace({
              path: "/user/PreChangePassWord",
            });
          }
          if (checkUserResult.data.data == 2) {
            this.$router.replace({
              path: "/user/CheckSmsCode",
            });
          }
        }
      }

      var welcome = await GetUserWelcome();
      console.log("welcome");
      if (welcome.data.success) {
        this.userInfo = welcome.data.data;
        if (
          welcome.data.scoreMessage !== undefined &&
          welcome.data.scoreMessage != ""
        ) {
          Toast({
            message: welcome.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        }
      }
      //获取功能模块顺序
      let resultBase = await getSetingBase({
        fromType: 1,
        current: 1,
        pageSize: 1000,
        FilterData: { pageType: 1 },
      });
      if (resultBase.data.data.length > 0) {
        this.setingBase = resultBase.data.data;
      }

      //功能模块
      let result101 = await getSetingList({
        fromType: 1,
        current: 1,
        pageSize: 1000,
        FilterData: { setTypes: [100, 101, 102, 103, 104, 211] },
      });
      if (result101.data.data.length > 0) {
        this.NavItmes = [];
        result101.data.data.forEach((element) => {
          //轮播图
          if (element.setType == 100) {
            this.swipeImages.push({
              imageUrl: element.imageUrl,
              link: element.urlPath,
            });
          }

          //功能模块
          if (element.setType == 101) {
            this.NavItmes.push({
              src: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              creationTime: element.creationTime,
              isMain: false,
            });
          }
          //TODO: 滚动文注释
          // //滚动文字
          if (element.setType == 102) {
            this.NoticeItem = {
              link: element.urlPath,
              title: element.value,
            };
            this.Notice = element.value;
          }
          //热门推荐带图
          if (element.setType == 103) {
            this.hotimages.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          //热门推荐不带图
          if (element.setType == 104) {
            this.hottexts.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 106) {
            this.hottexts.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 10000) {
            this.newAtricle100.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 10100) {
            this.newAtricle101.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          if (element.setType == 10200) {
            this.newAtricle102.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 10600) {
            this.newAtricle106.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          if (element.setType == 3) {
            this.newProduct.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          if (element.setType == 4) {
            this.newCourse.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }
          if (element.setType == 41 || element.setType == 211) {
            this.newCourseOffline.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
            });
          }

          //中酿心声
          if (element.setType == 5) {
            this.newBBs.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
              headImgurl: element.headImgurl,
              icon: element.icon,
              trueName: element.trueName,
            });
          }

          if (element.setType == 6) {
            this.newCloudDisk.push({
              id: element.id,
              imageUrl: element.imageUrl,
              link: element.urlPath,
              title: element.value,
              readCount: element.readCount,
              creationTime: element.creationTime,
              headImgurl: element.headImgurl,
              trueName: element.trueName,
            });
          }

          //
        });
      }
      if (this.hotimages.length > 0) {
        this.hotimagespuls = [];
        let h2 = [];
        for (let index = 0; index < this.hotimages.length; index++) {
          h2.push(this.hotimages[index]);
          if (h2.length == 2 || index == this.hotimages.length - 1) {
            this.hotimagespuls.push(h2);
            h2 = [];
          }
        }
      }

      var systemScore = await GetSystemScore();

      var workDayResult = await GetWorkDay();
      if (workDayResult.data.success) {
        this.workDay = workDayResult.data.data;
      } else {
        this.workDay = "";
      }

      var workDayImageResult = await GetShareEndeavorImage();
      if (workDayImageResult.data.success) {
        this.workDayImage = workDayImageResult.data.data;
      } else {
        this.workDayImage = "";
      }
      setTimeout(() => {
        this.workDayImageShow = false;
      }, 4000);

      var systemRemind = await getSystemRemindByUser();
      if (systemRemind.data.success) {
        if (
          systemRemind.data.data != undefined &&
          systemRemind.data.data.id !== undefined
        ) {
          this.hassystemRemind = true;
          this.showdialog = true;
          this.systemRemind = systemRemind.data.data;
        }
      }

      this.loading = false;

      if (
        welcome.data.scoreMessage !== undefined &&
        welcome.data.scoreMessage != ""
      ) {
        Toast({
          message: welcome.data.scoreMessage,
          icon: "/image/signin_point_add.png",
        });
      }

      //有系统积分
      if (
        systemScore.data.scoreMessage !== undefined &&
        systemScore.data.scoreMessage != ""
      ) {

        var singinIcon="/image/signin_point_add.png"
        if(systemScore.data.scoreMessage.indexOf("能量值") >=0){
          singinIcon="/image/singinscoreblock1.png";
        }

        if (
          welcome.data.scoreMessage !== undefined &&
          welcome.data.scoreMessage != ""
        ) {
          //两秒后   var welcome = await GetUserWelcome();

          setTimeout(() => {
            Toast({
              message: systemScore.data.scoreMessage,
              icon: singinIcon,
              duration:3000,
              className:"myToast",
            });
          }, 2000);
        } else {
          Toast({
            message: systemScore.data.scoreMessage,
            icon: singinIcon,
              duration:3000,
              className:"myToast",
          });
        }
      }
    },

    // 退出登录
    async lgout() {
      try {
        Toast("登录异常，请重新登录");
        this.clearUser();
        window.location.replace(window.location.origin + "/#/login");
      } catch (error) {
        console.log(error);
      }
    },
    pimageClick(link) {
      if (link != "") {
        if (link.indexOf("http") >= 0) {
          window.location.href = link;
        } else {
          this.$router.push(link);

          //存储 scrollTop 的值
          this.scroll = this.$refs.wrapper.scrollTop;
        }
      }
    },
    workDayImageShowClick() {
      this.workDayImageShow = !this.workDayImageShow;
    },
    hotClick(h) {
      if (h.link != "") {
        this.$router.push(h.link);
        //阅读量加1
        updateReadCount(h.id);

        //存储 scrollTop 的值
        this.scroll = this.$refs.wrapper.scrollTop;
      }
    },
    navClick(nav) {
      if (nav.isMain) {
        return;
      }

      if (nav.link == undefined || nav.link == "") {
        Toast(nav.title + " 功能开发中，敬请期待");
        return;
      }

      //存储 scrollTop 的值
      this.scroll = this.$refs.wrapper.scrollTop;
      //Grafana
      if (
        nav.link.indexOf("http://182.48.111.206:8100") >= 0 ||
        nav.link.indexOf("https://km.bjznpz.com/grafana") >= 0
      ) {
        createGrafanaUser().then((d) => {
          if (!d.data || !d.data.success) {
            Toast("检查可视化系统用户失败");
          } else {
            window.location.href = "/grafana"; //"http://182.48.111.206:8100/public/pages/login.html?user="+
          }
        });
      } else if (
        nav.link.indexOf("http:") >= 0 ||
        nav.link.indexOf("https:") >= 0
      ) {
        window.location.href = nav.link + ( nav.link.indexOf("?") == -1 ? "?" : "&" ) + "token=" + store.state.user.token;
      } else {
        this.$router.push({ path: nav.link });
      }
    },

    navNotice(nav) {
      if (nav.link == undefined || nav.link == "") {
        console.log("没有跳转信息");
      } else {
        console.log(nav.link.indexOf("http"));
        if (nav.link.indexOf("http") >= 0) {
          window.location.href = nav.link;
        } else {
          this.$router.push({ path: nav.link });
        }
      }
    },

    onSearch() {
      //if (!this.keyword) return
      //Toast("全局搜索功能开发中，敬请期待");
      //onSearch
      if (this.keyword != "") {
        this.$router.push({ path: "/search/list", query: { q: this.keyword } });
      }
    },

    toUserInfo() {
      this.$router.push({
        path: "/user",
        query: {},
      });
    },

    closedialog() {
      this.showdialog = false;
    },
    async receivedSystemMessageHandle() {
      //systemRemind
      this.disabledReceived = true;
      const aresult = await receivedSystemMessage(this.systemRemind);
      if (aresult.data.success) {
        //this.closedialog();
        if (
          aresult.data.scoreMessage !== undefined &&
          aresult.data.scoreMessage != ""
        ) {
          Toast({
            message: aresult.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        } else {
          
          if(this.systemRemind.typeId == 5){
            Toast("成功");
            this.closedialog();
          }
          if(this.systemRemind.typeId == 6){
            Toast("领取成功，可在我的证书查看");
            this.closedialog();
          }
        }
        if (this.systemRemind.typeId == 5) {
          //this.closedialog();
          if (
            this.systemRemind.linkUrl != undefined &&
            this.systemRemind.linkUrl.length > 0
          ) {
            if (this.systemRemind.linkUrl.indexOf("http") >= 0) {
              window.location.href = this.systemRemind.linkUrl;
            } else {
              this.$router.push({ path: this.systemRemind.linkUrl });
            }
          }
        }
      }
    },
  },
};
</script>
<style>
.myToast{
    background-color: rgba(0, 0, 0, 0.8);
}
</style>
<style lang="less" scoped>
@nav-bar-height: 100px;

.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  /deep/.w100 {
    margin: 2px 8px 2px 8px;
    // /deep/ img {
    //   width: 100%;
    //   height: 80px;
    //   max-height: 90px;
    // }
    img {
      width: 100%;
      //height: 80px;
      max-height: 150px;
    }
  }
  /deep/.van-grid-item__content {
    padding: 16px 3px;
  }
}
.mydivider {
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
  border-width: 0;
}
.mydivider::before {
  content: "";
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-color: inherit;
  border-style: inherit;
  border-width: 1px 0 0;
}

.tops {
  font-size: 10px;
  transform: skewX(-30deg);
  background: linear-gradient(to right, #8f2e32, #9d1d22);
  color: #fff;
  text-align: center;
  padding: 0px 5px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 5px;
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
  /deep/ div {
    transform: skewX(30deg);
  }
}

.midText {
  position: absolute;
  left: 50%;
  background-color: #ffffff;
  padding: 0 15px;
  transform: translateX(-50%) translateY(-50%);
}

.loading {
  text-align: center;
}
.van-search {
  padding: 0px 18px;
}
.van-grid {
  /deep/ .van-icon__image {
    width: 1em;
    height: 1em;
  }
}

//手机等小屏
@media (min-width: 200px) {
  .my-swipe .van-swipe-item {
    width: 100%;
    height: 150px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    //# background-color: #39a9ed;
  }

  .my-swipe img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .chance-form {
    /deep/ img {
      max-width: 100%;
    }
  }
  .new-chance-dialog .J-close-btn {
  position: absolute;
  right: 50%;
  bottom: -1.16rem;
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  width: 0.76rem;
  height: 0.76rem;
  line-height: 0.76rem;
  font-size: 0.62rem;
  color: #fff;
  background: url(/image/close_icon.png) no-repeat;
  background-size: contain;
  background-position: center center;
}
}
//大于700的PC等大屏
@media (min-width: 700px) {
  .my-swipe .van-swipe-item {
    width: 100%;
    height: 300px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    //# background-color: #39a9ed;
  }

  .my-swipe img {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .chance-form {
    /deep/ img {
      max-width: 45%;
    }
  }
  .new-chance-dialog .J-close-btn {
  position: absolute;
  right: 50%;
  bottom: -45px;
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 35px;
  color: #fff;
  background: url(/image/close_icon.png) no-repeat;
  background-size: contain;
  background-position: center center;
}
}

.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}
.bar_btn {
  width: 36px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    box-shadow: 0 0 10px #999;
    transform: scale(1, 0.6);
  }
  .van-icon-wap-nav {
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
    line-height: 35px;
    position: relative;
    z-index: 1000;
    &::before {
      font-size: 20px;
    }
  }
}
.van-action-sheet {
  max-height: 100%;
  height: 100%;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
}
.van-notice-bar {
  background: #fff;
  color: black;
  /deep/ i {
    color: #9d1d22;
  }
}

/*弹出系统提醒样式  */

.new-chance-dialog {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.5);
}

.new-chance-dialog.customize-dialog .wraper {
  height: 6.88rem;
  background-position: center;
  background-size: 100%;
}

.new-chance-dialog.customize-dialog .wraper .chance-form {
  margin-top: 5.12rem;
}

.new-chance-dialog.customize-dialog .wraper .chance-form .chance-form-item {
  height: 1.08rem;
  width: 5.6rem;
  line-height: 1.08rem;
  border: solid 1px #ccc;
  background-color: #fff;
}

.new-chance-dialog.customize-dialog .wraper .chance-form .chance-form-btn {
  position: absolute;
  margin: 0;
  right: 0.48rem;
  top: 0;
  width: auto;
  max-width: 3.2rem;
  min-width: 2.4rem;
}

.new-chance-dialog.customize-dialog
  .wraper
  .chance-form
  .chance-form-btn
  button {
  font-size: 0.35rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0 0.053333rem 0.053333rem 0;
}

.new-chance-dialog .title-wrap {
  width: 100%;
}

.new-chance-dialog .wraper {
  position: absolute;
  width: 80%;
  max-width: 580px;
  left: 50%;
  top: 50%;
  background: #fff;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  z-index: 103;
  border-radius: 0.053333rem;
  //background-image: url(//as.zbjimg.com/static/nodejs-chatm-mobile-web/components/add_chance_new/img/bg1.6399845c.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 30%;
}

.new-chance-dialog .wraper::after {
  content: "";
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  left: 0;
  bottom: 0;
  //background: url(//as.zbjimg.com/static/nodejs-chatm-mobile-web/components/add_chance_new/img/bg2.a9cd20f5.png) no-repeat;
  background-size: 124%;
  z-index: -1;
  background-position: -0.4rem 0.3rem;
}

// .new-chance-dialog .J-close-btn {
//   position: absolute;
//   right: 50%;
//   bottom: -1.16rem;
//   -ms-transform: translateX(50%);
//   transform: translateX(50%);
//   width: 0.76rem;
//   height: 0.76rem;
//   line-height: 0.76rem;
//   font-size: 0.62rem;
//   color: #fff;
//   background: url(/image/close_icon.png) no-repeat;
//   background-size: contain;
//   background-position: center center;
// }

.new-chance-dialog .main-title {
  padding: 0.48rem 0 0.27rem 0;
  background: #fff;
  line-height: 1;
  text-align: center;
  border-radius: 0.053333rem 0.053333rem 0 0;
}

.new-chance-dialog .main-title p {
  font-size: 0.48rem;
  font-weight: 700;
  display: inline-block;
  position: relative;
}

.new-chance-dialog .main-title p span {
  display: inline-block;
  max-width: 7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.new-chance-dialog .main-title p::after {
  content: "";
  display: block;
  width: 0.093333rem;
  height: 0.106667rem;
  background-color: #005bfe;
  position: absolute;
  left: -0.293333rem;
  top: 0.173333rem;
}

.new-chance-dialog .main-title p::before {
  content: "";
  display: block;
  width: 0.093333rem;
  height: 0.106667rem;
  background-color: #005bfe;
  position: absolute;
  right: -0.293333rem;
  top: 0.173333rem;
}

.new-chance-dialog .sub-title {
  line-height: 1;
}

.new-chance-dialog .sub-title p {
  display: inline-block;
  font-size: 0.35rem;
  color: #999;
}

.new-chance-dialog .chance-form {
  position: relative;
}

.new-chance-dialog .chance-form form {
  margin: 0 0.4rem;
}

.new-chance-dialog .chance-form .chance-form-item {
  position: relative;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.43rem;
  height: 1.01rem;
  background-color: #f5f7fb;
  border-radius: 0.053333rem;
}

.new-chance-dialog .chance-form .chance-form-link label:before {
  position: absolute;
  content: "*";
  font-size: 0.48rem;
  left: -9px;
  top: 50%;
  -ms-transform: translateY(-34%);
  transform: translateY(-34%);
  color: red;
}

.new-chance-dialog .chance-form label {
  position: relative;
  display: inline-block;
  margin-left: 0.4rem;
  font-size: 0.37rem;
  color: rgba(0, 0, 0, 0.859);
}

.new-chance-dialog .chance-form input {
  position: relative;
  text-indent: 0.32rem;
  outline: 0;
  border: 0;
  border-radius: 0;
  width: 5.2rem;
  color: #666;
  font-size: 0.37rem;
  background-color: transparent;
}

.new-chance-dialog .chance-form .chacne-form-tip {
  margin-top: 0.27rem;
  width: 3.97rem;
  height: 0.37rem;

  background-size: contain;
  background-position: center center;
}

.new-chance-dialog .chance-form .service-agrement {
  position: relative;
  margin-top: 0.4rem;
  font-size: 0.32rem;
  color: rgba(0, 0, 0, 0.459);
  white-space: nowrap;
}

.new-chance-dialog .chance-form .service-agrement .description {
  margin-left: -0.24rem;
}

.new-chance-dialog .chance-form .service-agrement .service-radio {
  float: left;
  margin-top: 1px;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  width: 0.43rem;
  height: 0.43rem;
  background: #fff;
}

.new-chance-dialog .chance-form .service-agrement .service-radio.checked {
  border: 1px solid transparent;
  width: 0.43rem;
  height: 0.43rem;
  //background: url(//as.zbjimg.com/static/nodejs-chatm-mobile-web/components/add_chance_new/img/checked.38b6b0b7.png) no-repeat;
  background-size: contain;
  background-position: center center;
}

.new-chance-dialog .chance-form .service-agrement a {
  color: #005bfe;
}

.new-chance-dialog .chance-form .service-agrement .service {
  position: relative;
  left: -6px;
}

.new-chance-dialog .chance-form .service-agrement .special-service {
  position: relative;
  left: -18px;
}

.new-chance-dialog .chance-form .chance-form-btn {
  margin: 0.39rem 0 0.75rem 0;
  width: 100%;
  height: 1.07rem;
}

.new-chance-dialog .chance-form .chance-form-btn button {
  outline: 0;
  border: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 2px;
  font-size: 0.4rem;
  font-weight: 700;
  color: #fff;
  background-color: #005bfe;
  border-radius: 0.533333rem;
}

.new-chance-dialog .chance-form .safe-tips {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  //background: url(//as.zbjimg.com/static/nodejs-chatm-mobile-web/components/add_chance_new/img/safe.f0fd213b.gif) no-repeat;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  right: 5px;
  background-size: 100%;
}

.new-chance-dialog .chance-form .J-form-modify {
  position: absolute;
  top: 50%;
  right: 15px;
  color: #005bfe;
  font-size: 0.32rem;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.new-chance-dialog .chance-load {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 0.53rem;
  height: 0.53rem;
  transform: translate3d(-50%, -50%, 0);
  //background: url(//as.zbjimg.com/static/nodejs-chatm-mobile-web/components/add_chance_new/img/loading.e92d9458.gif) no-repeat;
  background-size: contain;
  background-position: center center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.NavList {
  /deep/.van-grid-item__content {
    padding: 7px 3px;
  }
  /deep/.van-grid-item__icon + .van-grid-item__text {
    margin-top: 3px;
  }
}
.userCertificate{
  background-color: rgba(0, 0, 0, 0.35)!important;
}

</style>
